<template>
  <!--  同步练习管理-->
  <div class="synchronizeExercises">
    <a-spin :indicator="indicator" :spinning="spinning">
      <div style="overflow: hidden;display: flex;justify-content: space-between">
        <div class="menu_select">
          <h4 style="padding: 10px">职称考试分类</h4>
          <a-menu
              style="width: 100%"
              mode="inline"
              v-model="menu"
          >
            <a-menu-item
                v-for="(item,index) in typeInfo"
                :key="index"
                @click="leftChange(item.exes,index)"
            >
              {{item.name}}
            </a-menu-item>
          </a-menu>
        </div>
        <div class="InfoTable">
          <div class="select_Info">
            <div>
              所属分类：<a-button style="margin: 5px;font-family: 微软雅黑" v-for="(list,index) in InfoTable" size="small" :class="{ liBackground:provincesStyle == index}" id="provincesBtn"
                             @click="provincesChange(index,list)">{{list.name}}</a-button>
            </div>
          </div>
          <div class="table_Info">
            <a-button type="primary" @click="()=>this.addShow = true">新增</a-button>
            <a-table :columns="columns" style="min-height: 500px" :data-source="tableList" rowKey="id"   :pagination="false"
            >
              <span slot="get_type" slot-scope="text,item">
                {{ item.get_type === 'DEFINED' ? '自定义' : '随机'}}
              </span>
              <span slot="question_code" slot-scope="text,item">
                {{ item.get_type === 'DEFINED' ? '——' : item.question_code}}
              </span>
              <span slot="number" slot-scope="text,item">
                <a v-if="item.get_type === 'DEFINED'" @click="examBtn(item.id)" >{{ item.question_num}}</a>
                    <span v-else>{{item.question_num}}</span>
                </span>
              <span slot="switch" slot-scope="text,item">
                <a-switch v-model="item.is_open=== 1" checked-children="是" un-checked-children="否" @click="switchChange(item.id,item.is_open === 1? 0 : 1)"/>
              </span>
              <span slot="operate" slot-scope="text,item,index" style="display: flex;justify-content: space-between;width: 70%">
                <a v-if="index !== 0" @click="moveUp(item.id)">上移</a>
                <a v-if="index !== tableList.length - 1" @click="moveDown(item.id)">下移</a>
              <a style="margin-right: 10px" @click="editBtn(item.id)">修改</a>
              <a-popconfirm
                  title="确定删除?"
                  ok-text="是"
                  cancel-text="否"
                  @confirm="delTitle(item.id)"
                  @cancel="cancel"
              >
            <a >删除</a>
            </a-popconfirm>
            </span>
            </a-table>
            <div  style="display: flex;justify-content: flex-end;right: 35px;margin-top: 15px;">
              <a-pagination v-model="pagination.current"
                            :total="pagination.total"
                            @change="paginationChange" />
            </div>
          </div>
        </div>
      </div>

      <a-drawer
          title="新增"
          width="600"
          :visible="addShow"
          @close="()=>{this.addShow = false}"
      >
        <a-form-model :rules="rules" :model="addForm" :label-col="labelCol" :wrapper-col="wrapperCol" ref="addItem" >
          <a-form-model-item label="封面图" prop="cover">
            <div style="display: flex;flex-wrap: wrap;justify-content: space-between;width:70%;margin-bottom: -10px">
              <div class="uploadImg">
                <input style="cursor:pointer;width: 100px;height: 100px;opacity: 0;" accept="image/*" type="file" @change="uploadPic('addCover')" ref="addCover" />
              </div>
              <div class="upload_image">
                <img
                    alt=""
                    v-if="addForm.cover"
                    style="width: 95px;height:95px;"
                    :src="addForm.cover"
                />
              </div>
            </div>
            <span style="font-size: 10px">注：尺寸 宽280*高158，大小不超过500kb</span>
          </a-form-model-item>
          <a-form-model-item label="同步练习分类" prop="exe_type">
            <a-select
                placeholder="选择分类"
                v-model="addForm.exe_type"
            >
              <a-select-option v-for="item in InfoTable" :key="item.id" :value="item.id">
                {{item.name}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="名称" prop="name">
            <a-input  v-model="addForm.name"  placeholder="输入名称"  />
          </a-form-model-item>
          <a-form-model-item label="题目类型" prop="get_type">
            <a-radio-group v-model="addForm.get_type" @change="typeChange">
              <a-radio value="DEFINED" >
                自定义
              </a-radio>
              <a-radio value= "RDNDOM">
                随机
              </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item label="题库分类" prop="question_type" v-if="addForm.get_type ==='RDNDOM' ">
            <a-select
                placeholder="选择分类"
                v-model="addForm.question_type"
            >
              <a-select-option v-for="item in ExamList" :key="item.id" :value="item.id">
                {{item.name}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="随机数量" prop="question_num" v-if="addForm.get_type ==='RDNDOM' ">
            <a-input  v-model="addForm.question_num"  placeholder="输入数量"  />
          </a-form-model-item>
          <a-form-model-item label="是否开启" prop="is_open">
            <a-switch @change="openWitch" checked-children="是" un-checked-children="否" default-un-checked />
          </a-form-model-item>
        </a-form-model>

        <div
            :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'right',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px',
        }"
        >
          <a-button style="marginRight: 8px" @click="()=>{this.addShow = false}">
            取消
          </a-button>
          <a-button type="primary" @click="addOk">
            确认
          </a-button>
        </div>
      </a-drawer>
      <a-drawer
          title="修改"
          width="600"
          :visible="editShow"
          @close="()=>{this.editShow = false}"
      >
        <a-form-model :rules="rules" :model="editForm" :label-col="labelCol" :wrapper-col="wrapperCol" ref="editItem" >
          <a-form-model-item label="封面图" prop="cover">
            <div style="display: flex;flex-wrap: wrap;justify-content: space-between;width:70%;margin-bottom: -10px">
              <div class="uploadImg">
                <input style="cursor:pointer;width: 100px;height: 100px;opacity: 0;" accept="image/*" type="file" @change="uploadPic('editCover')" ref="editCover" />
              </div>
              <div class="upload_image">
                <img
                    alt=""
                    v-if="editForm.cover"
                    style="width: 95px;height:95px;"
                    :src="editForm.cover"
                />
              </div>
            </div>
            <span style="font-size: 10px">注：尺寸 宽280*高158，大小不超过500kb</span>
          </a-form-model-item>
          <a-form-model-item label="同步练习分类" prop="exe_type">
            <a-select
                placeholder="选择分类"
                v-model="editForm.exe_type"
            >
              <a-select-option v-for="item in InfoTable" :key="item.id" :value="item.id">
                {{item.name}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="名称" prop="name">
            <a-input  v-model="editForm.name"  placeholder="输入名称"  />
          </a-form-model-item>
          <a-form-model-item label="题目类型" prop="get_type">
            <a-radio-group v-model="editForm.get_type"  @change="typeEdit">
              <a-radio value='DEFINED' >
                自定义
              </a-radio>
              <a-radio value='RDNDOM'>
                随机
              </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item label="题库分类" prop="question_type" v-if="editForm.get_type ==='RDNDOM' ">
            <a-select
                placeholder="选择分类"
                v-model="editForm.question_type"
            >
              <a-select-option v-for="item in ExamList" :key="item.id" :value="item.id">
                {{item.name}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="随机数量" prop="question_num" v-if="editForm.get_type ==='RDNDOM' ">
            <a-input  v-model="editForm.question_num"  placeholder="输入数量"  />
          </a-form-model-item>
          <a-form-model-item label="是否开启" prop="is_open">
            <a-switch :checked="editForm.is_open!==0" @click="openEdit(editForm.is_open === 1? 0 : 1)"
                      checked-children="是" un-checked-children="否"  />
          </a-form-model-item>
        </a-form-model>

        <div
            :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'right',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px',
        }"
        >
          <a-button style="marginRight: 8px" @click="()=>{this.editShow = false}">
            取消
          </a-button>
          <a-button type="primary" @click="editOk">
            确认
          </a-button>
        </div>
      </a-drawer>

      <a-modal v-model="examShow" title="题目" width="70%" :footer="null" >
        <div style="display: flex;justify-content: space-between">
          <div>
            名称：<a-input style="width: 150px;margin-right: 10px" v-model="searchName"  @keyup.enter="searchEnter"/>
            <a-button  @click="searchExam">查询</a-button>
          </div>
          <div style="width: 35%;display: flex;justify-content: space-between">
            <a-button type="primary" @click="()=>{this.addExamShow = true}">新增</a-button>
            <a-button type="primary">从题库选择</a-button>
            <a-button type="primary">按分类导入</a-button>
          </div>
        </div>
        <a-table style="margin: 10px;min-height: 400px" :columns="ExamTitle" :data-source="examTable" rowKey="id"   :pagination="false">
           <span slot="name" slot-scope="text,item,index">
           {{'【'}} {{item.type === 'radio' ? '单选':'多选'}}{{'】'}}{{item.name}}
          </span>
          <span slot="operate" slot-scope="text,item,index">
           <a v-if="index !== 0" @click="examUp(item.id)">上移</a>
                <a  v-if="index !== examTable.length - 1" @click="examDown(item.id)">下移</a>
              <a style="margin-right: 10px;margin-left: 10px" @click="editBtnExam(item.id)">修改</a>
              <a-popconfirm
                  title="确定删除?"
                  ok-text="是"
                  cancel-text="否"
                  @confirm="delExam(item.id)"
                  @cancel="cancel"
              >
            <a >删除</a>
            </a-popconfirm>
          </span>
        </a-table>
        <div  style="display: flex;justify-content: flex-end;right: 35px;margin-top: 15px;">
          <a-pagination v-model="examPage.current"
                        :total="examPage.total"
                        @change="examPageChange" />
        </div>
      </a-modal>

      <!--    题目新增-->
      <a-drawer
          title="新增"
          width="600"
          :visible="addExamShow"
          @close="()=>{this.addExamShow = false}"
      >
        <a-form-model :rules="rules" :model="addExamForm" :label-col="labelCol" :wrapper-col="wrapperCol" ref="addExamItem" >
          <a-form-model-item label="类型" prop="type">
            <a-radio-group v-model="addExamForm.type">
              <a-radio value="radio" >
                单选
              </a-radio>
              <a-radio value= "checkbox">
                多选
              </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item label="分类" >
            <a-select
                placeholder="选择分类"
                v-model="addExamForm.question_code"
                mode="multiple"
                :maxTagCount= 3
                showArrow
                :maxTagTextLength = 7
                @focus="()=>{this.getExamList()}"
                @blur="()=>{this.examName =[]}"
            >
              <a-select-option v-for="item in examName" :key="item.id" :value="item.name">
                {{item.name}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="题目名称" prop="name">
            <a-input  v-model.trim="addExamForm.name"  placeholder="输入名称" @change="addTitle" />
            <ul  class="titleList" v-for="item in titleList">
              {{item.name}}
            </ul>
          </a-form-model-item>
          <a-form-model-item label="选项" prop="options">
            <div style="display: flex;align-items: center"  v-for="(item,index) in addExamForm.options" :key="index">
              <a-input placeholder="输入选项" style="width: 220px;margin-right: 10px" v-model="item.title" />
              <a-icon type="plus-circle" v-if="index <=0" style="cursor: pointer;font-size: 18px;color: #1890ff;margin-right: 10px"  @click="addOptions" />
              <a-icon type="minus-circle" v-if="index!==0" style="cursor: pointer;font-size: 18px;margin-right: 10px"   @click="delOptions(item,index)" />
              <input type="checkbox" :id="'check'+index" @change="resultKey(item,index)">
              正确答案
            </div>
          </a-form-model-item>
          <a-form-model-item label="解析" >
            <a-textarea v-model="addExamForm.parse" />
          </a-form-model-item>
        </a-form-model>

        <div
            :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'right',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px',
        }"
        >
          <a-button style="marginRight: 8px" @click="()=>{this.addExamShow = false}">
            取消
          </a-button>
          <a-button type="primary" @click="addExamOk">
            确认
          </a-button>
        </div>
      </a-drawer>

<!--      题目修改-->
      <a-drawer
          title="修改"
          width="600"
          :visible="editExamShow"
          @close="()=>{this.editExamShow = false}"
      >
        <a-form-model :rules="rules" :model="editExamForm" :label-col="labelCol" :wrapper-col="wrapperCol" ref="editExamItem" >
          <a-form-model-item label="类型" prop="type">
            <a-radio-group v-model="editExamForm.type">
              <a-radio value="radio" >
                单选
              </a-radio>
              <a-radio value= "checkbox">
                多选
              </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item label="分类" >
            <a-select
                placeholder="选择分类"
                v-model="editExamForm.question_code"
                mode="multiple"
                :maxTagCount= 3
                showArrow
                :maxTagTextLength = 7
            >
              <a-select-option v-for="item in examName" :key="item.id" :value="item.name">
                {{item.name}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="题目名称" prop="name">
            <a-input  v-model="editExamForm.name"  placeholder="输入名称" @change="addTitle" />
            <ul  class="titleList" v-for="item in titleList">
              {{item.name}}
            </ul>
          </a-form-model-item>
          <a-form-model-item label="选项" prop="options">
            <div style="display: flex;align-items: center"  v-for="(item,index) in editExamForm.options" :key="index">
              <a-input placeholder="输入选项" style="width: 220px;margin-right: 10px" v-model="item.title" />
              <a-icon type="plus-circle" v-if="index <=0" style="cursor: pointer;font-size: 18px;color: #1890ff;margin-right: 10px"  @click="editOptions" />
              <a-icon type="minus-circle" v-if="index!==0" style="cursor: pointer;font-size: 18px;margin-right: 10px"   @click="delEditOptions(item,index)" />
              <input type="checkbox" :value= 1  v-model="item.answer" :id="'check'+index" @change="resultKeyEdit(item,index)">
              正确答案
            </div>
          </a-form-model-item>
          <a-form-model-item label="解析" >
            <a-textarea v-model="editExamForm.parse" />
          </a-form-model-item>
        </a-form-model>

        <div
            :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'right',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px',
        }"
        >
          <a-button style="marginRight: 8px" @click="()=>{this.editExamShow = false}">
            取消
          </a-button>
          <a-button type="primary" @click="editExamOk">
            确认
          </a-button>
        </div>
      </a-drawer>


    </a-spin>
  </div>
</template>

<script>

import {
  delExercises,
  delTitleExam, getExam, getExercises, getExercisesId,
  getTitleExam,
  getTitleId,
  getTitleList, postDownExam, postDownExercises, postExam, postExercises,
  postTitleExam,
  postTitleType, postUpExam, postUpExercises, putExercises, putExercisesType, putTitleExam
} from "@/service/titleExam_api";
import {update} from "@/utils/update";


export default {
  name: "synchronizeExercises",
  data(){
    return{
      value: undefined,
      addShow:false,
      editShow:false,
      spinning:false,
      examShow:false,
      editExamShow:false,
      addExamShow:false,
      addExamForm:{
        options:[
          {
            answer:0,
            title:''
          }
        ],
        question_code:[],
        parse:''
      },
      typeForm:{},
      provincesStyle:0,
      editForm:{},
      editExamForm:{},
      addForm:{
        is_open:0,
        cover:''
      },
      indicator: <a-icon type="loading" style="font-size: 24px" spin />,
      labelCol: { span: 5},
      wrapperCol: { span: 16 },
      searchName:'',
      rules: {
        exe_type:[{ required: true, message: '请选择分类', trigger: 'blur' }],
        question_type:[{ required: true, message: '请选择分类', trigger: 'blur' }],
        question_code:[{ required: true, message: '请选择分类', trigger: 'blur' }],
        question_num:[{ required: true, message: '请输入数量', trigger: 'blur' }],
        cover:[{ required: true, message: '请上传封面图', trigger: 'blur' }],
        name:[{ required: true, message: '请输入名称', trigger: 'blur' }],
        code:[{ required: true, message: '请输入code值', trigger: 'blur' }],
        type:[{ required: true, message: '请选择类型', trigger: 'blur' }],
        get_type:[{ required: true, message: '请选择类型', trigger: 'blur' }],
        options:[{required:true,message:'请输入选项',trigger: 'blur'}],
        titleExam:[{ required: true, message: '请输入名称', trigger: 'blur' }],
        parse:[{ required: true, message: '请输入解析', trigger: 'blur' }],
        key: [{required: true, message: '请输入', trigger: 'blur'}],
      },
      columns:[
        {
          title: '题目名称',
          dataIndex: 'name',
        },
        {
          title: '题目类型',
          scopedSlots: { customRender: 'get_type' },
        },
        {
          title: '题库分类',
          scopedSlots: { customRender: 'question_code' },
        },
        {
          title: '题目数量',
          scopedSlots: { customRender: 'number' },
        },
        {
          title: '是否开启',
          scopedSlots: { customRender: 'switch' },
        },
        {
          title: '操作',
          scopedSlots: { customRender: 'operate' },
        },
      ],
      ExamTitle:[
        {
          title: '序号',
          dataIndex: 'index',
          key: 'index',
          customRender: (text,record,index) => `${index+1}`,
          align:'center',
          width:'8%'
        },
        {
          title: '题目名称',
          scopedSlots: { customRender: 'name' },
        },
        {
          title: '操作',
          scopedSlots: { customRender: 'operate' },
          width:'20%'
        },
      ],
      InfoTable:[],
      typeInfo:[],
      menu:[0],
      titleList:[],
      tableList:[],
      ExamList:[],
      examName:[],
      pagination:{
        total:0,
        current: 1,
        pageSize:10,
      },
      examPage:{
        total:0,
        current: 1,
        pageSize:10,
      },
      typeId:0,
      examTable:[],
    }
  },

  created() {
    this.$store.dispatch('setManageHeaderTitle',"同步练习管理")
    const user_info = window.localStorage.getItem("user_info");
    this.user_name =  JSON.parse(user_info).user_name
  },
  mounted() {
    this.getTitleExam()
    this.getExam()

  },
  methods:{
    async getTitleExam(pageNum){
      // this.spinning =true
      const exam = await getExercises(pageNum)
      if (exam.code === 0){
        this.typeInfo = exam.data
        this.InfoTable = exam.data[0].exes
        this.typeId = this.InfoTable[0].id
        this.pagination.total = this.InfoTable[0].count
        this.tableList = this.InfoTable[0].exes
      }else {
        this.$message.error('接口获取出错' + exam.message)
      }
      // this.spinning =false
    },
    //获取题库分类
    async getExam(){
      const Info =await getTitleExam()
      this.ExamList = Info.data
    },
    async getExamList(TitleExam){
      const exam = await getTitleExam(TitleExam)
      if (exam.code === 0){
        this.examName = exam.data
      }else {
        this.$message.error('接口获取出错' + exam.message)
      }
    },
    //分页跳转
    paginationChange(e){
      this.getTitleExam(e)
    },
    //新增是否开启
    openWitch(checked){
      if (checked === false){
        this.addForm.is_open = 0
      }else {
        this.addForm.is_open = 1
      }
    },
    //左侧身份选择
    leftChange(value,index){
      if (value.length === 0 ){
        this.InfoTable = null
        this.tableList = null
      }else {
        this.provincesStyle = 0
        this.InfoTable = value
        this.tableList = value[0].exes
        this.typeId = value[0].id
        this.menu =index
      }
    },
    //所属分类选择
     provincesChange(index,e){
        this.provincesStyle = index;
        this.typeId = e.id
        this.tableList = e.exes
    },
    //查询题目
    async examBtn(id,pageNum,name){
      const exam =await getExam(id,pageNum,name)
      this.examTable = exam.data.qtype
      this.examPage.total = exam.data.count
      this.examShow = true
      this.examId = id
    },
    examPageChange(e){
      this.examBtn(this.examId,e)
    },
    typeChange(e){
      if ( e.target.value === 'DEFINED'){
        delete this.addForm.question_num
        delete this.addForm.question_type
      }
    },
    typeEdit(e){
      if ( e.target.value === 'DEFINED'){
        delete this.editForm.question_num
        delete this.editForm.question_type
      }
    },
    //新增选项
    addOptions(){
      let ifTrue = true
      this.addExamForm.options.forEach(item => {
        if (item.title === '') {
          this.$message.warning('请完善选项')
          ifTrue = false
        }})
      if (ifTrue) {
        this.addExamForm.options.push({
          answer:0,
          title:''
        })}
    },
    //题目是否重复
    async addTitle(e){
      if (e.target.value !== '' & e.target.value !== undefined && e.target.value !== null){
        const search =await getTitleList(e.target.value)
        this.titleList = search.data
        let arr = []
        arr.push({
          name: e.target.value
        })
        for(let i = 0; i < this.titleList.length; i++) {
          for(let j = 0; j < arr.length; j++) {
            if(arr[j].name === this.titleList[i].name){
              this.$message.error('题目名称重复，重新输入')
            }}}
      }else {
        this.titleList = []
      }
    },
    resultKey(row,index){
      let all = document.getElementById("check"+index);
      if (all.checked===true){
        row.answer = 1
      }else {
        row.answer = 0
      }
    },
    editOptions(){
      let ifTrue = true
      this.editExamForm.options.forEach(item => {
        if (item.title === '') {
          this.$message.warning('请完善选项')
          ifTrue = false
        }})
      if (ifTrue) {
        this.editExamForm.options.push({
          answer:0,
          title:''
        })}
    },
    delOptions(item,index){
      this.addExamForm.options.splice(index, 1)
    },
    delEditOptions(item,index){
      this.editExamForm.options.splice(index, 1)
    },
    //修改是否正确答案
    resultKeyEdit(row,index){
      let all = document.getElementById("check"+index);
      if (all.checked===true){
        row.answer = 1
      }else {
        row.answer = 0
      }
    },
    //搜索题目
     searchExam(){
       this.examBtn(this.examId,'',this.searchName)
       this.pagination.current = 1
    },
    searchEnter(){
      this.searchExam()
    },
    async edit(){
      this.$refs.editExamItem.validate(async valid =>{
        if(valid){
          let arr =[]
          for (let i=0;i<this.editExamForm.options.length;i++){
            arr.push({
              title:this.editExamForm.options[i].title,
              answer:this.editExamForm.options[i].answer,
              id:i
            })
          }
          this.editExamForm.options =arr
          const add =await putTitleExam(this.editExamId,this.editExamForm)
          if (add.code === 0){
            await this.examBtn(this.examId)
            this.$message.success('修改成功')
            this.$refs.editExamItem.resetFields();
            this.editExamShow = false
          }
        }else {
          this.$message.error("输入有空~")
          return false;
        }
      })
    },
    //修改题目
     editExamOk(){
      for(let i = 0; i < this.editExamForm.options.length; i++) {
        if (this.editExamForm.options[i].answer === 1){
          switch (this.editExamForm.type){
            case 'radio' :
              let num = 0
              for (let  j =0; j<this.editExamForm.options.length;j++){
                if (this.editExamForm.options[j].answer === 1){
                  num ++
                }
              }
              if ( num > 1 ){
                this.$message.warning('重新勾选正确答案')
                return false;
              }else {
                this.edit()
              }
              break;
            case 'checkbox':
              this.edit()
              break;
          }
          return true;
        }
      }
      this.$message.error('未选择正确答案')
      return  false;
    },
    async add(){
      this.$refs.addExamItem.validate(async valid =>{
        if(valid){
          let arr =[]
          for (let i=0;i<this.addExamForm.options.length;i++){
            arr.push({
              title:this.addExamForm.options[i].title,
              answer:this.addExamForm.options[i].answer,
              id:i
            })
          }
          this.addExamForm.options =arr
          const add =await postExam(this.examId,this.user_name,this.addExamForm)
          if (add.code === 0){
            await this.examBtn(this.examId)
            this.$message.success('添加成功')
            this.$refs.addExamItem.resetFields();
            this.addExamForm={
              options:[
                {
                  answer:0,
                  title:''
                }
              ],
              question_code:[],
              parse:''
            }
            this.addExamShow = false
          }
        }else {
          this.$message.error("输入有空~")
          return false;
        }
      })
    },
    //新增题目
     addExamOk(){
      for(let i = 0; i < this.addExamForm.options.length; i++) {
        if (this.addExamForm.options[i].answer === 1){
          switch (this.addExamForm.type){
            case 'radio' :
              let num = 0
              for (let  j =0; j<this.addExamForm.options.length;j++){
                if (this.addExamForm.options[j].answer === 1){
                  num ++
                }
              }
              if ( num > 1 ){
                this.$message.warning('重新勾选正确答案')
                return false;
              }else {
                this.add()
              }
              break;
            case 'checkbox':
              this.add()
              break;
          }
          return true;
        }}
      this.$message.error('未选择正确答案')
      return  false;
    },
    //题目修改btn
    async editBtnExam(id){
      this.editExamId = id
      const edit =await getTitleId(id)
      if (edit.code === 0){
        this.editExamForm = edit.data
      }
      this.editExamShow = true
    await this.getExamList()
    },
    //上传封面图
    async uploadPic(type){
      const _this = this;
      let file = this.$refs[type].files[0];
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function(e) {
        let image = new Image();
        image.src = e.target.result;
        image.onload = async function () {
          let w = this.width;
          let h = this.height;
          if ( file.size > 500000) {
            _this.$message.warn('请上传尺寸为200px*200px,大小500k以内的图片');
            return false;
          } else {
            const image = await update(file);
            if (image.code === 0) {
              switch (type) {
                case'addCover':
                  _this.addForm.cover = image.data.url
                  break;
                case'editCover':
                  _this.editForm.cover = image.data.url
                  break;
              }
              _this.$message.success('上传成功')
            } else {
              _this.$message.error("上传失败，请重试。", image.message)
            }
          }
        }
      }
    },

    //新增确认
    async addOk(){
      this.$refs.addItem.validate(async valid =>{
        if(valid){
          const add =await postExercises(this.user_name,this.addForm)
          if (add.code === 0){
            await this.getTitleExam()
            this.InfoTable = this.typeInfo[this.menu].exes
            this.tableList = this.InfoTable[this.provincesStyle].exes
            this.provincesStyle = 0
            this.$message.success('添加成功')
            this.$refs.addItem.resetFields();
            this.addShow = false
          }
        }else {
          this.$message.error("输入有空~")
          return false;
        }
      })
    },
    //练习题上移
    async moveUp(id){
     const move =  await  postUpExercises(id,this.typeId)
      if (move.code ===0){
         await this.getTitleExam()
        this.InfoTable = this.typeInfo[this.menu].exes
        this.tableList = this.InfoTable[this.provincesStyle].exes
        this.$message.success('操作成功')
      }
    },
    //练习题下移
    async moveDown(id){
       const move =await postDownExercises(id,this.typeId)
      if (move.code ===0){
        await this.getTitleExam()
        this.InfoTable = this.typeInfo[this.menu].exes
        this.tableList = this.InfoTable[this.provincesStyle].exes
        this.$message.success('操作成功')
      }
    },
    //题目上移
     examUp(id){
      postUpExam(id,this.examId)
      this.timer = setTimeout(()=>{
        this.examBtn(this.examId)
        this.$message.success('操作成功')
      },1000);
    },
    //题目下移
    examDown(id){
      postDownExam(id,this.examId)
      this.timer = setTimeout(()=>{
        this.examBtn(this.examId)
        this.$message.success('操作成功')
      },1000);
    },
    //修改Btn
    async editBtn(id){
      this.editId = id
      const edit =await getExercisesId(id)
      if (edit.code === 0){
        this.editForm = edit.data
      }
      await this.getExam()
      this.editShow = true
    },
    //是否开启
    async switchChange(id,open){
      const data = {
        is_open : open
      }
      const Info = await  putExercises(id,data)
      if (Info.code === 0){
        await this.getTitleExam()
        this.InfoTable = this.typeInfo[this.menu].exes
        this.tableList = this.InfoTable[this.provincesStyle].exes
        this.$message.success('操作成功')
      }else {
        this.$message.error('操作失败' + Info.message)
      }
    },
    //修改
    async editOk(){
      this.$refs.editItem.validate(async valid =>{
        if(valid){
          const edit =await putExercises(this.editForm.id,this.editForm)
          if (edit.code === 0){
            await this.getTitleExam()
            this.InfoTable = this.typeInfo[this.menu].exes
            this.tableList = this.InfoTable[this.provincesStyle].exes
            this.$message.success('修改成功')
            this.$refs.editItem.resetFields();
            this.editShow = false
          }
        }else {
          this.$message.error("输入有空~")
          return false;
        }
      })
    },
    //修改是否开启
    openEdit(open){
      this.editForm.is_open = open
    },
    //删除
    async delExam(id){
      const Info =await delTitleExam(id)
      if (Info.code === 0){
        await this.examBtn(this.examId)
        this.$message.success('删除成功')
      }else {
        this.$message.error('删除失败')
      }
    },
    async delTitle(id){
      const Info =await delExercises(id)
      if (Info.code === 0){
        await this.getTitleExam()
        this.InfoTable = this.typeInfo[this.menu].exes
        this.tableList = this.InfoTable[this.provincesStyle].exes
        this.$message.success('删除成功')
      }else {
        this.$message.error('删除失败')
      }
    },
    //取消删除
    cancel(){
      this.$message.warning('取消删除');
    },
  }
}
</script>

<style lang="scss" scoped>
.liBackground {
  background: #1890ff !important;
  color: white;
}
.titleList{
  padding: 0;
  margin: 0;
}
.uploadImg{
  border: #DDDDDD 2px dashed;
  width: 100px;
  height: 100px;
  margin: 0 auto;
  background-image: url("../../../assets/add.png");
  background-size:100% 100%;
  background-repeat:no-repeat;
  cursor: pointer;
}
.upload_image{
  border: #DDDDDD 2px dashed;
  width: 100px;
  height: 100px;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
.synchronizeExercises{
  margin: -15px -15px;
  background-color: rgba(240, 242, 245, 1);
  .menu_select{
    background-color: white;
    width: 18%;
    height: 100%;
    margin-bottom: -1000px;
    padding-bottom: 1000px;
  }
  .InfoTable{
    width: 81%;
    margin-bottom: -1000px;
    padding-bottom: 1000px;
    .select_Info{
      display: flex;
      height: 70px;
      padding: 20px;
      margin-bottom: 10px;
      background-color: white;
    }
    .table_Info{
      padding: 20px;
      background-color: white;
    }
  }
}
</style>
